import Layout from "../../components/layouts/layout";
import {useNotification} from "../../hooks/useNotification";
import {useRef} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useComponentCategories, useComponentList, useLang} from "../../hooks";
import {BsGlobe, BsPencilSquare} from "react-icons/bs";
import {Breadcrumb, Button, Form} from "react-bootstrap";
import {MdDeleteOutline} from "react-icons/md";
import DataTable from "react-data-table-component";
import {FaUserPlus} from "react-icons/fa";
import {FloatingButton} from "../../components";
import ComponentGuard from "../../helpers/ComponentGuard";
import {Permission} from "../../configs/constants";
import './index.scss';
import {useForm} from "react-hook-form";
import {Tooltip, Whisper} from "rsuite";
import {DeleteButton, EditButton, LanguageButton} from "../../components/Buttons";

export function ComponentListPage() {
  const {t} = useLang();
  const {
    componentList, pagination,
    changePagination, deleteComponent, isLoading, error, filterDispatch
  } = useComponentList();
  const {categories, isLoading: isCategoryLoading, error: catErr} = useComponentCategories();
  const {notifyError, notifySuccess} = useNotification();
  const searchBoxRef = useRef();
  const navigate = useNavigate();
  const {register, handleSubmit, control} = useForm();

  if (error) {
    notifyError(error);
    setTimeout(() => navigate("/"), 200);
  }

  const tooltip = (
    <Tooltip>
      This is a help <i>tooltip</i> .
    </Tooltip>
  );


  const handleDelete = async (id) => {
    try {
      await deleteComponent(id);
      notifySuccess({
        title: "components removed successfully"
      });
    } catch (err) {
      notifyError({
        title: "Failed to remove component!",
        message: err.message
      });
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row, index) => (pagination.page - 1) * pagination.limit + (index + 1), //RDT provides index by default
      width: "100px"
    },
    {
      name: t("components_manage_col_name"),
      selector: (row) => row.title,
      sortable: true
    },
    {
      name: "Layout Name",
      selector: (row) => row.layout_pack_details?.name,
      sortable: true
    },
    {
      name: "Type",
      selector: (row) => row?.component_category?.name,
      sortable: true
    },
    {
      name: "Category",
      selector: (row) => row?.identifier_id_details?.name,
      sortable: false
    },
    {
      name: t("components_manage_col_action"),
      selector: (m) => {
        return (
          <div id="componentListActions" className="d-flex gap-2">
            <ComponentGuard needsPermission={[Permission.ComponentUpdate]}>
              <EditButton to={`/components/edit/${m.id}`}/>
            </ComponentGuard>
            <ComponentGuard needsPermission={[Permission.ComponentDelete]}>
              <DeleteButton handleDelete={() => handleDelete(m.id)}/>
            </ComponentGuard>
            <ComponentGuard needsPermission={[Permission.ComponentLanguagesList]}>
              <LanguageButton to={`/langs/component/${m.id}`}/>
            </ComponentGuard>
          </div>
        );
      }
    }
  ];

  return (
    <>
      <Layout>
        <header>
          <div className="d-flex justify-content-between">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item active>
                  {t("components_manage_components")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </header>
        <section id="generalComponentList" className="mb-5 pb-5">
          <h2>{t("components_manage_componentsList")}</h2>
          <div className="bg-light rounded p-4 mt-4">
            <Form
              onSubmit={handleSubmit((data) => {
                filterDispatch({
                  type: "reInitialize",
                  payload: data
                });
              })}
            >
              <div className="d-flex flex-column align-items-stretch align-items-md-end flex-md-row gap-2">
                <Form.Group controlId="category">
                  <Form.Label>{t("posts_manage_category")}</Form.Label>
                  <Form.Control {...register("category")} as="select" custom>
                    <option value="">None</option>
                    {categories?.map((term) => (
                      <option value={term.value}>{term.label}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group
                  controlId="search"
                  onSubmit={handleSubmit((data) => {
                  })}
                >
                  <Form.Label>{t("posts_manage_search")}</Form.Label>
                  <Form.Control
                    {...register("term")}
                    placeholder={t("post_manage_search_plhd")}
                    custom
                  ></Form.Control>
                </Form.Group>
                <div className="ml-auto">
                  <Button className="px-5" type="submit">
                    {t("posts_manage_go")}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          <DataTable
            columns={columns}
            data={componentList.components}
            progressPending={isLoading}
            pagination
            paginationServer
            paginationComponentOptions={{noRowsPerPage: true}}
            paginationTotalRows={componentList.total}
            onChangePage={(page) => {
              changePagination({page});
            }}
            fixedHeader
            fixedHeaderScrollHeight="800px"
            highlightOnHover
          />
        </section>
      </Layout>
      <ComponentGuard needsPermission={[Permission.ComponentStore]}>
        <FloatingButton onClick={() => {
          navigate("/components/new");
        }} icon={<FaUserPlus />} name={t("components_create_createNewComponent")} />
      </ComponentGuard>
    </>
  );
}