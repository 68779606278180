import {useRef} from "react";
import {Breadcrumb, Button} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {BsGlobe, BsPencilSquare} from "react-icons/bs";
import {CgPlayListAdd} from "react-icons/cg";
import {FaSearch} from "react-icons/fa";
import {MdDeleteOutline} from "react-icons/md";
import {Link, useNavigate} from "react-router-dom";
import {Input, InputGroup} from "rsuite";
import {FloatingButton} from "../../components";
import Layout from "../../components/layouts/layout";
import {Permission} from "../../configs/constants";
import ComponentGuard from "../../helpers/ComponentGuard";
import {useLang, useProductList} from "../../hooks";
import {useNotification} from "../../hooks/useNotification";
import {useTestimonialList} from "./hooks/useTestimonialList";

function ProductListPage() {
  const {t} = useLang();
  const {
    testimonialList, pagination,
    changePagination, deleteTestimonial, searchByTerm, isLoading,
    error, filter, filterDispatch
  } = useTestimonialList();
  const {notifyError, notifySuccess} = useNotification();
  const searchBoxRef = useRef();
  const navigate = useNavigate();

  if (error) {
    notifyError({
      title: "Failed to show testimonial",
      message: error.message
    });
  }

  const handleDelete = async (id) => {
    try {
      await deleteTestimonial(id);
      notifySuccess({
        title: "Testimonial removed successfully"
      });
    } catch (err) {
      notifyError({
        title: "Failed to remove testimonial!",
        message: err.message
      });
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row, index) => (pagination.page - 1) * pagination.limit + (index + 1), //RDT provides index by default
      width: "50px"
    },
    {
      name: t("testimonial_manage_col_name"),
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: t("testimonial_manage_col_action"),
      selector: (m) => {
        return (
          <div className="d-flex gap-2">
            <ComponentGuard needsPermission={[Permission.TestimonialUpdate]}>
              <Link to={`/testimonials/edit/${m.id}`} className="btn btn-secondary">
                <BsPencilSquare />
              </Link>
            </ComponentGuard>
            <ComponentGuard needsPermission={[Permission.TestimonialDelete]}>
              <Button variant="danger" onClick={() => handleDelete(m.id)}>
                <MdDeleteOutline />
              </Button>
            </ComponentGuard>
          </div>
        );
      }
    }
  ];

  console.log(testimonialList);

  return (
    <>
      <Layout>
        <header>
          <div className="d-flex justify-content-between">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item active>
                  {t("testimonial_manage_testimonial")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </header>
        <section id="albumList" className="mb-5 pb-5">
          <h2>{t("testimonial_manage_testimonialList")}</h2>
          <DataTable
            columns={columns}
            data={testimonialList.testimonials}
            progressPending={isLoading}
            pagination
            paginationServer
            paginationComponentOptions={{noRowsPerPage: true}}
            paginationTotalRows={testimonialList.total}
            onChangePage={(page) => {
              changePagination({page});
            }}
            fixedHeader
            fixedHeaderScrollHeight="800px"
            highlightOnHover
            subHeader
            subHeaderComponent={
              <InputGroup className="mb-3" style={{width: "300px"}}>
                <Input ref={searchBoxRef} placeholder={t("products_manage_searchByName")} />
                <InputGroup.Button onClick={() => filterDispatch({
                  type: "set",
                  payload: {key: "term", value: searchBoxRef.current.value}
                })}>
                  <FaSearch />
                </InputGroup.Button>
              </InputGroup>
            }
            subHeaderAlign="right"
          />
        </section>
      </Layout>
      <ComponentGuard needsPermission={[Permission.TestimonialStore]}>
        <FloatingButton
          onClick={() => {
            navigate("/testimonials/new");
          }}
          icon={<CgPlayListAdd />}
          name={t("testimonial_manage_newTestimonial")}
        />
      </ComponentGuard>
    </>
  );
}

export default ProductListPage;

