import {useRef} from "react";
import {Breadcrumb, Button} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {BsPencilSquare} from "react-icons/bs";
import {CgPlayListAdd} from "react-icons/cg";
import {FaSearch} from "react-icons/fa";
import {MdDeleteOutline} from "react-icons/md";
import {Link, useNavigate} from "react-router-dom";
import {Input, InputGroup} from "rsuite";
import {FloatingButton} from "../../components";
import Layout from "../../components/layouts/layout";
import {Permission} from "../../configs/constants";
import ComponentGuard from "../../helpers/ComponentGuard";
import {useNotification} from "../../hooks/useNotification";
import moment from "moment";
import {useLang} from "../../hooks";
import {usePaginatedList} from "../../hooks/usePaginatedList";
import {getPaginatedPricing, removePricing} from "./pricing.service";

export function PricingListPage() {
  const searchBoxRef = useRef();
  const {t} = useLang();
  const {
    list,
    changePagination,
    error,
    isLoading,
    pagination,
    filterDispatch,
    removeItem,
  } = usePaginatedList(getPaginatedPricing, removePricing)

  const {notifyError, notifySuccess} = useNotification();

  const navigate = useNavigate();

  if (error) {
    notifyError(error);
    setTimeout(() => navigate("/"), 200);
  }

  const handleDelete = async (id) => {
    try {
      await removeItem(id);
      notifySuccess({
        title: "Item removed successfully"
      });
    } catch (err) {
      notifyError({
        title: "Failed remove",
        message: err.message
      });
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row, index) =>
        (pagination.page - 1) * pagination.limit + (index + 1), //RDT provides index by default
      width: "50px"
    },
    {
      name: t("pricing_package_name"),
      selector: (row) => row.package_name,
      sortable: true
    },
    {
      name: t("pricing_price"),
      selector: (row) => row.price
    },
    {
      name: t("pricing_duration"),
      selector: (row) => row.duration
    },
    {
      name: t("pages_manage_col_createdAt"),
      selector: (m) => <span>{moment(m.created_at).format("DD-MM-YYYY (hh:mm:ss a)")}</span>
    },
    {
      name: t("pages_manage_col_action"),
      selector: (m) => {
        return (
          <div className="d-flex gap-2">
            <ComponentGuard needsPermission={[Permission.PricingUpdate]}>
              <Link
                to={`/pricing/${m.id}/edit`}
                className="btn btn-secondary"
              >
                <BsPencilSquare />
              </Link>
            </ComponentGuard>
            <ComponentGuard needsPermission={[Permission.PricingDelete]}>
              <Button onClick={() => handleDelete(m.id)}>
                <MdDeleteOutline />
              </Button>
            </ComponentGuard>
          </div>
        );
      }
    }
  ];

  return (
    <>
      <Layout>
        <header>
          <div className="d-flex justify-content-between">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item active>
                  {t("pricing_list")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </header>
        <section id="list" className="mb-5 pb-5">
          <h2>{t("pricingList")}</h2>
          <DataTable
            columns={columns}
            data={list.data}
            progressPending={isLoading}
            pagination
            paginationServer
            paginationComponentOptions={{noRowsPerPage: true}}
            paginationTotalRows={list.total}
            onChangePage={(page) => {
              changePagination({page});
            }}
            fixedHeader
            fixedHeaderScrollHeight="800px"
            highlightOnHover
            subHeader
            subHeaderComponent={
              <InputGroup className="mb-3" style={{width: "300px"}}>
                <Input ref={searchBoxRef} placeholder={t("pages_manage_searchByName")} />
                <InputGroup.Button
                  onClick={() => {
                    filterDispatch({
                      action: "set",
                      payload: {key: "term", value: searchBoxRef.current.value}
                    });
                  }}
                >
                  <FaSearch />
                </InputGroup.Button>
              </InputGroup>
            }
            subHeaderAlign="right"
          />
        </section>
      </Layout>
      <ComponentGuard needsPermission={[Permission.PricingStore]}>
        <FloatingButton
          onClick={() => {
            navigate("/pricing/new");
          }}
          icon={<CgPlayListAdd />}
          name={t("pricing_create_new")}
        />
      </ComponentGuard>
    </>
  );
}

