import {Breadcrumb, Button, Form} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {useForm} from "react-hook-form";
import {BsGlobe, BsPencilSquare} from "react-icons/bs";
import {MdDeleteOutline} from "react-icons/md";
import {Link, useNavigate} from "react-router-dom";
import {FloatingButton} from "../../components";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {Permission} from "../../configs/constants";
import ComponentGuard from "../../helpers/ComponentGuard";
import {useContentList, useLang, useTermList} from "../../hooks";
import {useNotification} from "../../hooks/useNotification";
import {IoExtensionPuzzleOutline} from "react-icons/io5";

export function ContentListPage() {
  const {notifyError, notifySuccess} = useNotification();
  const {termList, error: termError, isLoading: termLoading} = useTermList(9, {
    page: 1, limit: 1000,});
  const {t} = useLang();

  const {
    filterDispatch,
    contentList,
    pagination,
    changePagination,
    error,
    isLoading,
    deleteContent,
  } = useContentList();

  const {register, handleSubmit} = useForm();

  const navigate = useNavigate();
  if (termError || error) {
    notifyError(error);
    setTimeout(() => navigate("/"), 200);
  }

  const handleDelete = async (id) => {
    try {
      await deleteContent(id);
      notifySuccess({
        title: "Content removed successfully",
      });
    } catch (err) {
      notifyError({
        title: "Failed to remove content!",
        message: err.message,
      });
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row, index) =>
        (pagination.page - 1) * pagination.limit + (index + 1), //RDT provides index by default
      width: "50px",
    },
    {
      name: t("contents_manage_col_name"),
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: t("contents_manage_col_term"),
      selector: (m) => m.terms.name,
    },
    {
      name: t("contents_manage_col_action"),
      selector: (m) => (
        <div className="d-flex gap-2">
          <Link to={`/contents/${m.id}/edit`} className="btn btn-secondary">
            <BsPencilSquare/>
          </Link>
          <Button variant="danger" onClick={() => handleDelete(m.id)}>
            <MdDeleteOutline/>
          </Button>
          <Link to={`/langs/content/${m.id}`} className="btn btn-info">
            <BsGlobe/>
          </Link>
        </div>
      ),
    },
  ];

  return (
    <>
      <Layout>
        {termLoading && <CircleLoader/>}
        <header>
          <div className="d-flex justify-content-between">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item active>
                  {t("contents_contents")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </header>
        <section id="content-list" className="mb-5 pb-5">
          <h2>{t("contents_contentsList")}</h2>
          <div className="bg-light rounded p-4 mt-4">
            <Form
                onSubmit={handleSubmit((data) => {
                  console.log(data);
                  filterDispatch({
                    type: "reInitialize",
                    payload: data,
                  });
                })}
            >
              <div className="d-flex flex-column align-items-stretch align-items-md-end flex-md-row gap-2">
                <Form.Group controlId="termId">
                  <Form.Label>{t("contents_manage_term")}</Form.Label>
                  <Form.Control {...register("termId")} as="select" custom>
                    <option value="">None</option>
                    {termList.terms.map((term) => (
                        <option value={term.id}>{term.name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group
                    controlId="search"
                    onSubmit={handleSubmit((data) => {
                    })}
                >
                  <Form.Label>{t("contents_manage_search")}</Form.Label>
                  <Form.Control
                      {...register("term")}
                      placeholder={t("contents_manage_search_plhd")}
                      custom
                  ></Form.Control>
                </Form.Group>
                <div className="ml-auto">
                  <Button className="px-5" type="submit">
                    {t("contents_manage_go")}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          <DataTable
              columns={columns}
              data={contentList.contents}
              progressPending={isLoading}
              pagination
              paginationServer
              paginationComponentOptions={{noRowsPerPage: true}}
              paginationTotalRows={contentList.total}
              onChangePage={(page) => {
                changePagination({page});
              }}
              fixedHeader
              fixedHeaderScrollHeight="800px"
              highlightOnHover
              subHeader
              subHeaderAlign="right"
          />
        </section>
      </Layout>
      <ComponentGuard needsPermission={[Permission.TaxonomyStore]}>
        <FloatingButton
            onClick={() => {
              navigate("/contents/new");
            }}
            icon={<IoExtensionPuzzleOutline/>}
            name={t("contents_manage_newContent")}
        />
      </ComponentGuard>
    </>
  );
}

export default ContentListPage;
