import {useRef} from "react";
import {Breadcrumb, Button} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {BsPencilSquare} from "react-icons/bs";
import {CgPlayListAdd} from "react-icons/cg";
import {FaSearch} from "react-icons/fa";
import {MdDeleteOutline} from "react-icons/md";
import {Link, useNavigate} from "react-router-dom";
import {Input, InputGroup} from "rsuite";
import {FloatingButton} from "../../components";
import Layout from "../../components/layouts/layout";
import {Permission} from "../../configs/constants";
import ComponentGuard from "../../helpers/ComponentGuard";
import {useNotification} from "../../hooks/useNotification";
import {usePageList} from "../../hooks/usePageList";
import moment from "moment";
import {useLang} from "../../hooks";

export function PageListPage() {
  const {t} = useLang();
  const {
    pageList,
    pagination,
    changePagination,
    error,
    isLoading,
    deletePage,
    filterDispatch
  } = usePageList();

  const {notifyError, notifySuccess} = useNotification();
  const searchBoxRef = useRef();
  const navigate = useNavigate();

  if (error) {
    notifyError(error);
    setTimeout(() => navigate("/"), 200);
  }

  const handleDelete = async (id) => {
    try {
      await deletePage(id);
      notifySuccess({
        title: "Page removed successfully"
      });
    } catch (err) {
      notifyError({
        title: "Failed to remove page!",
        message: err.message
      });
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row, index) =>
        (pagination.page - 1) * pagination.limit + (index + 1), //RDT provides index by default
      width: "50px"
    },
    {
      name: t("pages_manage_col_title"),
      selector: (row) => row.title,
      sortable: true
    },
    {
      name: t("pages_manage_col_slug"),
      selector: (m) => m.slug
    },
    {
      name: t("pages_manage_col_createdAt"),
      selector: (m) => <span>{moment(m.created_at).format("DD-MM-YYYY (hh:mm:ss a)")}</span>
    },
    {
      name: t("pages_manage_col_action"),
      selector: (m) => {
        return (
          <div className="d-flex gap-2">
            <ComponentGuard needsPermission={[Permission.PageUpdate]}>
              <Link
                to={`/pages/${m.id}/edit`}
                className="btn btn-secondary"
              >
                <BsPencilSquare />
              </Link>
            </ComponentGuard>
            <ComponentGuard needsPermission={[Permission.PageDelete]}>
              <Button onClick={() => handleDelete(m.id)}>
                <MdDeleteOutline />
              </Button>
            </ComponentGuard>
          </div>
        );
      }
    }
  ];

  return (
    <>
      <Layout>
        <header>
          <div className="d-flex justify-content-between">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item active>
                  {t("pages_manage_pages")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </header>
        <section id="pageList" className="mb-5 pb-5">
          <h2>{t("pages_manage_pagesList")}</h2>
          <DataTable
            columns={columns}
            data={pageList.pages}
            progressPending={isLoading}
            pagination
            paginationServer
            paginationComponentOptions={{noRowsPerPage: true}}
            paginationTotalRows={pageList.total}
            onChangePage={(page) => {
              changePagination({page});
            }}
            fixedHeader
            fixedHeaderScrollHeight="800px"
            highlightOnHover
            subHeader
            subHeaderComponent={
              <InputGroup className="mb-3" style={{width: "300px"}}>
                <Input ref={searchBoxRef} placeholder={t("pages_manage_searchByName")} />
                <InputGroup.Button
                  onClick={() => filterDispatch({
                    type: "set",
                    payload: {key: "term", value: searchBoxRef.current.value}
                  })}
                >
                  <FaSearch />
                </InputGroup.Button>
              </InputGroup>
            }
            subHeaderAlign="right"
          />
        </section>
      </Layout>
      <ComponentGuard needsPermission={[Permission.PageStore]}>
        <FloatingButton
          onClick={() => {
            navigate("/pages/new");
          }}
          icon={<CgPlayListAdd />}
          name={t("pages_manage_newPage")}
        />
      </ComponentGuard>
    </>
  );
}

