import {FETCH_USER_ERR, FETCH_USER_REQ, FETCH_USER_SUCC, RESET_USER} from "./listType";

const initialState = {
    loading: false,
    data : [],
    err: ''
}

const UserReducer = (store = initialState, action) =>{
    switch(action.type){
        case FETCH_USER_REQ : 
            return{
                ...store ,
                loading: true,
            }
        case FETCH_USER_SUCC :
            return{
                loading: false,
                data: action.payload,
            }
        case FETCH_USER_ERR : 
            return{
                loading: false,
                data: [],
                err: action.payload
            }
        case RESET_USER :
            return initialState;
        default : return store
    }
}
export default UserReducer