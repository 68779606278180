import React, {useState, useEffect} from "react";
import "./role.css";
import {Link, useNavigate} from "react-router-dom";
import {Button , Badge, Breadcrumb} from "react-bootstrap";
import http from "../../http";
import DataTable from "react-data-table-component";
import Layout from "../../components/layouts/layout";
import loginCheck from "../../helpers/loginCheck";
import Cookies from "universal-cookie";
import ComponentGuard from "../../helpers/ComponentGuard";
import {useLang} from "../../hooks";
import {BackButton} from "../../components/BackButton/BackButton";

const RoleUserList = () => {

  const [show, setShow] = useState(false);
  const [id, setId] = useState("");
  const [users, setUsers] = useState([]);
  const [getDeleteUser, setDeleteUser] = useState(false);
  const [search, setSearch] = useState("");
  const [filterUsers, setFilterUsers] = useState([]);

  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pages, setPages] = useState();
  const [getCurrentPage, setCurrentPage] = useState();
  const cookies = new Cookies();
  const {t} = useLang();

  const navigate = useNavigate();
  useEffect(() => {
    if (!loginCheck()) {
      navigate("/login");
    }
  });

  useEffect(() => {
    fetchAllUsers(getCurrentPage);
  }, [getDeleteUser]);

  useEffect(() => {
    let query = search.length > 0 ? `users/name/${search}` : `users`;

    http.get(`/role/${query}?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`, {
        headers: {
          "Authorization": "Bearer " + cookies.get("userAuth").token
        }
      }
    ).then((response) => {
      setTotalRows(response.data.data.total);
      setFilterUsers(response.data.data.data);
    });
  }, [search]);


  const fetchAllUsers = async page => {
    setCurrentPage(page);
    setLoading(true);
    setPages(page);
    const response = await http.get(`/role/users?limit=${perPage}&page=${pages}&per_page=${perPage}&delay=1`, {
      headers: {
        "Authorization": "Bearer " + cookies.get("userAuth").token
      }
    });
    setFilterUsers(response.data.data.data);
    setUsers(response.data.data.data);
    setTotalRows(response.data.data.total);
    setLoading(false);
  };

  const handlePageChange = async (page) => {
    setCurrentPage(page);
    let query = search.length > 0 ? `users/name/${search}` : `users`;

    setLoading(true);
    const response = await http.get(`/role/${query}?limit=${perPage}&page=${page}&per_page=${perPage}&delay=1`, {
      headers: {
        "Authorization": "Bearer " + cookies.get("userAuth").token
      }
    });

    setUsers(response.data.data.data);
    setFilterUsers(response.data.data.data);
    setPerPage(perPage);
    setLoading(false);
  };

  useEffect(() => {
    fetchAllUsers(1); // fetch page 1 of users
  }, []);

  const columns = [
    {
      name: "#",
      cell: (row, index) => ((getCurrentPage - 1) * perPage) + (index + 1),  //RDT provides index by default
      width: "50px"
    },
    {
      name: t("acl_userRoleList_col_userName"),
      selector: row => row.name,
      sortable: true
    },
    {
      name: t("acl_userRoleList_col_roleName"),
      selector: row => (row.roles?.length > 0) ? row.roles?.map(ele => <Badge style={{marginRight: "10px"}} bg="warning"
                                                                              text="dark">{ele.name}</Badge>) :
        <Badge bg="warning" text="dark">N/A</Badge>,
      sortable: true,
      width: "700px"

    },
    {
      name: t("acl_userRoleList_col_action"),
      cell: (row) =>
        (
          <>
            <ComponentGuard needsPermission={["role.removeRoleFromUser"]}>
              <Link to={"delete-role/" + row.id}>
                <Button className="action__btn" variant="info">
                  {t("acl_userRoleList_col_deleteRole")}
                </Button>
              </Link>
            </ComponentGuard>
          </>
        )
    }
  ];
  return (
    <>
      <Layout>
        <header>
          <div className="d-flex justify-content-between">
            <Breadcrumb>
              <li className="breadcrumb-item">
                <Link to={`/role`}> {t("acl_roleList_roleList")}</Link>
              </li>
              <Breadcrumb.Item active>
                {t("acl_userRoleList_userRoleList")}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </header>

        <DataTable
          title={t("acl_userRoleList_userRoleList")}
          columns={columns}
          data={filterUsers}
          progressPending={loading}
          pagination
          paginationServer
          paginationComponentOptions={{noRowsPerPage: true}}
          paginationTotalRows={totalRows}
          // onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          fixedHeader
          fixedHeaderScrollHeight="800px"
          highlightOnHover
          subHeader
          subHeaderComponent={
            <input
              type="text"
              placeholder={t("acl_userRoleList_searchHere")}
              className="search_btn form-control"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          }
          subHeaderAlign="right"
        />
      </Layout>
    </>
  );
};

export default RoleUserList;