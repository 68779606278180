import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import ReactPlayer from "react-player";
import ReactPaginate from "react-paginate";
import Layout from "../../components/layouts/layout";

import {Button, Checkbox, CheckboxGroup, IconButton, Modal} from "rsuite";
import {Image, Card, Container, Row, Badge, Form} from "react-bootstrap";
import ComponentGuard from "../../helpers/ComponentGuard";

import {defaultStyles, FileIcon} from "react-file-icon";
import {FaFile, FaLink, FaPencilAlt} from "react-icons/fa";
import {FaFileCirclePlus} from "react-icons/fa6";

import "./media.css";
import SearchAndFilter from "./SearchAndFilter";
import {getUrlFromStoragePath} from "../../configs";
import {Permission} from "../../configs/constants";
import {FloatingButton} from "../../components";
import {useLang} from "../../hooks";
import {useNotification} from "../../hooks/useNotification";
import {useMediaList} from "./Hooks/useMediaList";
import CircleLoader from "../../components/Loaders/CircleLoader";
import {useMedia} from "./Hooks/useMedia";
import MediaCard from "./components/MediaCard";
import {get_url_extension} from "./helpers/media";

function MediaManagement() {
  const {t} = useLang();
  const navigate = useNavigate();

  const [ selectedId, setSelectedId ] = useState(null);
  const { mediaList, isLoading, changePagination, pagination,
    filter, filterDispatch, error, deleteMedia } = useMediaList();
  const { media, isLoading: isSingleLoading, error: isSingleError } = useMedia(selectedId);
  const { notifyError, notifySuccess}  = useNotification();
  const [openDelete, setOpenDelete] = useState(false);
  // Content Show Modal
  const handleOpen = (id) => {
    console.log("==============>",id);
    setSelectedId(id)
  };
  const handleClose = () => {
    setSelectedId(null);
  };

  //Delete Show Modal
  const handleDeleteOpen = (id) => {
    //handleClose()
    setOpenDelete(true);
  };
  const handleDeleteClose = () => {
    setOpenDelete(false);
  };

  //CheckBox Const
  const [value, setValue] = useState([]);
  const [data, setData] = useState([]);
  const handleCheckAll = (value, checked) => setValue(checked ? data : []);
  const handleChange = (value) => {
    setValue(value)
  };

  //convert datetime
  const convert_time = (datetime) => {
    const dateTimeString = datetime;
    const dateTime = new Date(dateTimeString);
    const options = {day: "2-digit", month: "short", year: "numeric"};
    const formattedDate = dateTime.toLocaleDateString("en-US", options);
    return formattedDate;
  };
  const handleDelete = async (id) => {
    try {
      await deleteMedia(id);
      setSelectedId(null);
      handleDeleteClose();
      notifySuccess({
        title: "Successful!",
        message:"Media Deleted Successfully!"
      })
    } catch (err) {
      notifyError({
        title: "Failed to delete",
        message: err.message
      })
    }
  };
  const handlePageClick = (data) => {
    changePagination({...pagination, page: data.selected +1});
  };

  useEffect(()=>{
    if(mediaList?.data.length > 0) {
      let tempAr = [];
      mediaList?.data.map((md) => tempAr.push(md.id));
      console.log("Select Check---<>",tempAr);
      setData(tempAr);
    }
  },[mediaList])

  return (
    <>
      <Layout>
        {isLoading && <CircleLoader/>}
        <div>
          <Container fluid>
            <Row>
              <h3>{t("media_mediaList")}</h3>
            </Row>
          </Container>
        </div>
        {/*  */}
        <SearchAndFilter filterDispatch={filterDispatch} changePagination={changePagination} />
        {
          mediaList?.data?.length > 0 ?
          <Checkbox indeterminate={value.length > 0 && value.length < data.length} checked={value.length === data.length}
                    onChange={handleCheckAll}>
            {t("media_selectAll")}
          </Checkbox> : <></>
        }
        <CheckboxGroup name="checkboxList" value={value} onChange={handleChange}>
          <Container>
            <div className="d-flex flex-wrap gap-1 justify-content-start">
              {mediaList?.data?.map((md) => (
                <MediaCard isSelect isEdit media={md} handleOpen={handleOpen}/>
              ))}
            </div>
          </Container>
        </CheckboxGroup>
        <div>
          {
            mediaList?.data.length > 0 ?
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={mediaList?.last_page}
              marginPagesDisplayed={3}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center m-0"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            /> : <></>
          }
        </div>
        <Modal size="lg" open={!!selectedId} onClose={handleClose}>
          <Modal.Header>
            <Modal.Title>
              <h5>{t("media_details_details")}</h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="container-fluid">
            <div className="row">
              <div className="col-md-6 d-flex justify-content-center">
                {media?.media_category?.name === "Image" && (
                  <Image className="d-block m-auto" style={{ height: "200px" }} src={getUrlFromStoragePath(media.original_url)} fluid />
                )}
                {media?.media_category?.name === "Video" && (
                  <div className="d-flex justify-content-center align-items-center">
                    <ReactPlayer
                      width={350} height={200}
                      url={getUrlFromStoragePath(media.original_url)}
                      onReady={true}
                      controls={true}
                    />
                  </div>
                )}
                {media?.media_category?.name === "Documents" && (
                  <div className="d-flex flex-column justify-content-center m-auto gap-2">
                    <div className="fileIcon">
                      <FileIcon extension={get_url_extension(media?.original_url)}
                                {...defaultStyles[get_url_extension(media?.original_url)]} />
                    </div>
                    <a
                      href={getUrlFromStoragePath(media.original_url)}
                      download="Example-PDF-document"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button color="blue" appearance="primary" className="d-block mx-auto">
                        Download
                      </Button>
                    </a>
                  </div>
                )}
                {media?.media_category?.name === "External Link" && (
                  <div className="d-flex justify-content-center align-items-center">
                    <ReactPlayer width={350} height={200} className="m-auto w-100" url={getUrlFromStoragePath(media.original_url)}
                                 controls={true} />
                  </div>
                )}
              </div>
              <div className="col-md-6">
                <div
                  className="d-flex justify-content-start"
                  style={{backgroundColor: "rgb(246, 246, 249)", width: "100%"}}
                >
                  <div className="text-muted" style={{width: "50%"}}>
                    <span>{t("media_details_id")}</span>
                    <br />
                    <span>{media?.id}</span>
                  </div>
                  <div className="text-muted" style={{width: "50%"}}>
                    <span>{t("media_details_date")}</span>
                    <br />
                    <span>{convert_time(media?.created_at)}</span>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-start"
                  style={{backgroundColor: "rgb(246, 246, 249)", width: "100%", marginBottom: "20px"}}
                >
                  <div className="text-muted" style={{width: "50%"}}>
                    <span>{t("media_details_extension")}</span>
                    <br />
                    <span>{Number(media?.media_category_id) !== 4 && get_url_extension(media?.original_url)}</span>
                  </div>
                </div>
                <div>
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>{t("media_input_title")}</Form.Label>
                      <Form.Control type="text"  defaultValue={media?.title} disabled />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>{t("media_input_alternativeText")}</Form.Label>
                      <Form.Control type="text" defaultValue={media?.alt_text} disabled/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>{t("media_input_description")}</Form.Label>
                      <Form.Control type="text" defaultValue={media?.description} disabled/>
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex gap-1">
              <ComponentGuard needsPermission={[Permission.MediaUpdate]}>
                <Link to={`/media/${media?.id}`}>
                  <Button onClick={handleClose} color="blue" appearance="primary">
                    {t("media_details_edit")}
                  </Button>
                </Link>
              </ComponentGuard>
              <ComponentGuard needsPermission={[Permission.MediaDelete]}>
                <Button
                  onClick={() => {
                    handleDeleteOpen(media?.id);
                  }}
                  color="red"
                  appearance="primary"
                >
                  {t("media_details_delete")}
                </Button>
              </ComponentGuard>
              <Button className="ml-auto" onClick={handleClose} appearance="subtle">
                {t("media_details_cancel")}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal backdrop={'static'} keyboard={false} open={openDelete} onClose={handleDeleteClose}>
          <Modal.Header>
            <Modal.Title>Delete Media</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to delete this item?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                handleDelete(media?.id);
              }}
              appearance="primary"
            >
              Yes
            </Button>
            <Button onClick={handleDeleteClose} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
      <ComponentGuard needsPermission={[Permission.MediaStore]}>
        <FloatingButton onClick={() => {
          navigate("/media/create");
        }} icon={<FaFileCirclePlus size={25} />} name={t("media_newMedia")} />
      </ComponentGuard>
    </>
  );
}

export default MediaManagement;
