import http from "../../http";
import {FETCH_USER_ERR, FETCH_USER_REQ, FETCH_USER_SUCC, RESET_USER} from "./listType";

export const fetch_user_req = () => {
  return {
    type: FETCH_USER_REQ
  }
}

export const fetch_user_succ = (data) => {
  return {
    type: FETCH_USER_SUCC,
    payload: data
  }
}

export const fetch_user_err = (err) => {
  return {
    type: FETCH_USER_ERR,
    payload: err
  }
}

export const reset_user = () => {
  return {
    type: RESET_USER
  }
}

export const fetchUsers = (cookies) => {
  return async (dispatch) => {
    dispatch(fetch_user_req());

    const userAuth = cookies.get('userAuth');
    if (!userAuth) {
      dispatch(fetch_user_err("User is not authenticated"));
      return;
    }

    const userId = userAuth.user?.id;
    const token = userAuth.token;

    try {
      const apiUserFetch = http.create({headers: {Authorization: `Bearer ${token}`}});
      const r = await apiUserFetch.get(`/users/get/${userId}`)
      const list_data = r.data.data;
      dispatch(fetch_user_succ(list_data));
    } catch (err) {
      const error = err.message;
      dispatch(fetch_user_err(error));
    }

    // http.get(`/users/get/${userId}`, {
    //   headers: {
    //     "Authorization": `Bearer ${token}`,
    //   }
    // })
    //   .then(response => {
    //     const list_data = response.data.data;
    //     dispatch(fetch_user_succ(list_data));
    //   })
    //   .catch(err => {
    //     const error = err.message;
    //     dispatch(fetch_user_err(error));
    //   });
  }
}
