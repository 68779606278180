import {Breadcrumb, Button} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {BsMenuButtonWideFill, BsPencilSquare} from "react-icons/bs";
import {HiViewGridAdd} from "react-icons/hi";
import {MdDeleteOutline} from "react-icons/md";
import {Link, useNavigate} from "react-router-dom";
import {FloatingButton} from "../../components";
import Layout from "../../components/layouts/layout";
import {useMenuList} from "./hooks/useMenuList";
import {useError} from "../../hooks/useError";
import {useLang} from "../../hooks";
import ComponentGuard from "../../helpers/ComponentGuard";
import {Permission} from "../../configs/constants";

export function MenuListPage() {
  const {error, menus, isLoading, deleteMenu, changePagination} =
    useMenuList();
  const {pushError} = useError();
  const navigate = useNavigate();
  const {t} = useLang();

  if (error) {
    pushError({
      title: "Something went wrong",
      message: "Failed to fetch menu"
    });
    navigate("/");
  }

  const handleDelete = async (id) => {
    try {
      await deleteMenu(id);
    } catch (err) {
      pushError({
        title: "Failed to remove menu",
        message: err.message
      });
    }
  };

  const menuListColumns = [
    {
      name: t("menu_col_title"),
      selector: (m) => m.name
    },
    {
      name: t("menu_col_slug"),
      selector: (m) => m.slug
    },
    {
      name: t("menu_col_action"),
      selector: (m) => (
        <div className="d-flex gap-2">
          <ComponentGuard needsPermission={[Permission.MenuUpdate]}>
            <Link to={`/menus/${m.id}/edit`} className="btn btn-secondary">
              <BsPencilSquare />
            </Link>
          </ComponentGuard>
          <ComponentGuard needsPermission={[Permission.MenuUpdate]}>
            <Link to={`/menus/${m.id}`} className="btn btn-light">
              <BsMenuButtonWideFill />
            </Link>
          </ComponentGuard>
          <ComponentGuard needsPermission={[Permission.MenuDestroy]}>
            <Button variant="danger" onClick={() => handleDelete(m.id)}>
              <MdDeleteOutline />
            </Button>
          </ComponentGuard>
        </div>
      )
    }
  ];

  return (
    <Layout>
      <header>
        <div className="d-flex justify-content-between">
          <div>
            <Breadcrumb>
              <Breadcrumb.Item active>
                {t("menu_manageMenu")}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </header>
      <section id="menu-list">
        <h2 className="text-dark">{t("menu_manageMenuList")}</h2>
        <DataTable
          responsive
          progressPending={isLoading}
          className="mt-4"
          columns={menuListColumns}
          data={menus.menus}
          pagination
          paginationServer
          paginationComponentOptions={{noRowsPerPage: true}}
          paginationTotalRows={menus.total}
          onChangePage={(page) => {
            changePagination({page});
          }}
        />
        <ComponentGuard needsPermission={[Permission.MenuStore]}>
        <FloatingButton
          onClick={() => {
            navigate("/menus/new");
          }}
          icon={<HiViewGridAdd />}
          name={t("menu_newMenu")}
        />
        </ComponentGuard>
      </section>
    </Layout>
  );
}
