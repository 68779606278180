import {Badge, Breadcrumb, Button, Form} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {Controller, useForm} from "react-hook-form";
import {BsGlobe, BsPencilSquare} from "react-icons/bs";
import {MdDeleteOutline} from "react-icons/md";
import {Link, useNavigate} from "react-router-dom";
import {FloatingButton} from "../../components";
import CircleLoader from "../../components/Loaders/CircleLoader";
import Layout from "../../components/layouts/layout";
import {Permission} from "../../configs/constants";
import ComponentGuard from "../../helpers/ComponentGuard";
import {useLang, usePostList, useTermList} from "../../hooks";
import {useNotification} from "../../hooks/useNotification";
import {IoExtensionPuzzleOutline} from "react-icons/io5";
import moment from "moment";
import {DatePicker} from "rsuite";

export function PostListPage() {
  const {t} = useLang();
  const {notifyError, notifySuccess} = useNotification();
  const {
    termList,
    error: termError,
    isLoading: termLoading
  } = useTermList(1, {limit: 1000, page: 1});

  const {
    filterDispatch,
    postList,
    pagination,
    changePagination,
    error,
    isLoading,
    deletePost
  } = usePostList();

  const {register, handleSubmit, control} = useForm();

  const navigate = useNavigate();
  if (termError || error) {
    notifyError(error);
    setTimeout(() => navigate("/"), 200);
  }

  const handleDelete = async (id) => {
    try {
      await deletePost(id);
      notifySuccess({
        title: "Post removed successfully"
      });
    } catch (err) {
      notifyError({
        title: "Failed to remove content!",
        message: err.message
      });
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row, index) =>
        (pagination.page - 1) * pagination.limit + (index + 1), //RDT provides index by default
      width: "50px"
    },
    {
      name: t("posts_manage_col_name"),
      selector: (row) => (
        <div className="text-truncate" style={{width: "200px"}}>
          {row.name}
        </div>
      ),
      sortable: true
    },
    {
      name: t("posts_manage_col_visibility"),
      selector: (row) =>
        row.is_show ? (
          <Badge bg="success">Public</Badge>
        ) : (
          <Badge bg="warning">Private</Badge>
        ),
      sortable: true
    },
    {
      name: t("posts_manage_col_category"),
      selector: (row) => row.post_category?.name ?? "Not Available",
      sortable: true
    },
    {
      name: t("posts_manage_col_postDate"),
      selector: (row) => row.post_date,
      sortable: true
    },
    {
      name: t("posts_manage_col_action"),
      selector: (m) => (
        <div className="d-flex gap-2">
          <ComponentGuard needsPermission={[Permission.PostsUpdate]}>
            <Link to={`/posts/${m.id}/edit`} className="btn btn-secondary">
              <BsPencilSquare />
            </Link>
          </ComponentGuard>
          <ComponentGuard needsPermission={[Permission.PostsDelete]}>
            <Button variant="danger" onClick={() => handleDelete(m.id)}>
              <MdDeleteOutline />
            </Button>
          </ComponentGuard>
          <ComponentGuard needsPermission={[Permission.PostLanguagesList]}>
            <Link to={`/langs/post/${m.id}`} className="btn btn-info">
              <BsGlobe />
            </Link>
          </ComponentGuard>
        </div>
      )
    }
  ];

  return (
    <>
      <Layout>
        {termLoading && <CircleLoader />}
        <header>
          <div className="d-flex justify-content-between">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item active>
                  {t("posts_manage_posts")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </header>
        <section id="post-list" className="mb-5 pb-5">
          <h2>{t("posts_manage_postsList")}</h2>
          <div className="bg-light rounded p-4 mt-4">
            <Form
              onSubmit={handleSubmit((data) => {
                if (data.post_date) {
                  data.post_date = moment(data.post_date).format("yyyy-MM-DD");
                }
                filterDispatch({
                  type: "reInitialize",
                  payload: data
                });
              })}
            >
              <div className="d-flex flex-column align-items-stretch align-items-md-end flex-md-row gap-2">
                <Form.Group controlId="category">
                  <Form.Label>{t("posts_manage_category")}</Form.Label>
                  <Form.Control {...register("category")} as="select" custom>
                    <option value="">None</option>
                    {termList.terms.map((term) => (
                      <option value={term.id}>{term.name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="visible">
                  <Form.Label>{t("posts_manage_visibility")}</Form.Label>
                  <Form.Control {...register("visible")} as="select" custom>
                    <option value={""}>None</option>
                    <option value={1}>Public</option>
                    <option value={0}>Private</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group md={4} controlId="post_date">
                  <Form.Label>{t("posts_manage_postDate")}</Form.Label>
                  <Controller
                    control={control}
                    name="post_date"
                    render={({field}) => (
                      <div>
                        <DatePicker {...field} format="dd-MM-yyyy" />
                      </div>
                    )}
                  />
                </Form.Group>
                <Form.Group
                  controlId="search"
                  onSubmit={handleSubmit((data) => {
                  })}
                >
                  <Form.Label>{t("posts_manage_search")}</Form.Label>
                  <Form.Control
                    {...register("term")}
                    placeholder={t("post_manage_search_plhd")}
                    custom
                  ></Form.Control>
                </Form.Group>
                <div className="ml-auto">
                  <Button className="px-5" type="submit">
                    {t("posts_manage_go")}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          <DataTable
            columns={columns}
            data={postList.posts}
            progressPending={isLoading}
            pagination
            paginationServer
            paginationComponentOptions={{noRowsPerPage: true}}
            paginationTotalRows={postList.total}
            onChangePage={(page) => {
              changePagination({page});
            }}
            fixedHeader
            fixedHeaderScrollHeight="800px"
            highlightOnHover
            subHeader
            subHeaderAlign="right"
          />
        </section>
      </Layout>
      <ComponentGuard needsPermission={[Permission.PostsStore]}>
        <FloatingButton
          onClick={() => {
            navigate("/posts/new");
          }}
          icon={<IoExtensionPuzzleOutline />}
          name={t("posts_manage_newPost")}
        />
      </ComponentGuard>
    </>
  );
}

export default PostListPage;
