import {useState} from "react";
import {Breadcrumb, Button, Form} from "react-bootstrap";
import DataTable from "react-data-table-component";
import { FaEye } from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {DateRangePicker} from "rsuite";
import Layout from "../../components/layouts/layout";
import {useLang} from "../../hooks";
import {useNotification} from "../../hooks/useNotification";
import {getPaginatedContactForm} from "../../services/contactForm";
import { Modal } from 'rsuite';
import GenericPdfDownloader from "../../components/GeneratePDF/GenericPdfDownloader";
import moment from "moment/moment";
import {Controller, useForm} from "react-hook-form";
import {exportToExcel} from "../../libs/utility/helper";
import CircleLoader from "../../components/Loaders/CircleLoader";
import {usePaginatedList} from "../../hooks/usePaginatedList";

function ContactFormListPage() {
  const {t} = useLang();
  const [fetchError, setFetchError] = useState(null);
  const [isExcelLoading, setIsExcelLoading] = useState(false);
  const [ selectedData, setSelectedData ] = useState(null)
  const {notifyError} = useNotification();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const {register, handleSubmit, control} = useForm();
  const {
    list,
    changePagination,
    error,
    isLoading,
    pagination,
    filterDispatch,
    removeItem,
  } = usePaginatedList(getPaginatedContactForm, ()=>{})

  const handleOpen = (id) => {
    setSelectedData(list?.data.find(item => item.id === id));
    console.log(selectedData);
    setOpen(true)
  };
  const handleClose = () => setOpen(false);

  if (error) {
    notifyError(error);
    setTimeout(() => navigate("/"), 200);
  }

  const fetchAllData = async () => {
    let allData = [];
    try {
      setIsExcelLoading(true)
      allData = await getPaginatedContactForm({page: 1, limit: 1000000,})
    } catch (err) {
      setFetchError({
        title: "Something went wrong",
        message: err.message
      })
    }finally {
      setIsExcelLoading(false)
    }
    return allData;
  }
  const handleExcelButtonClick = async () => {
    let fetchData = await fetchAllData();
    let excelData = fetchData?.data ?? null;
    const columnsToKeep = ['name', 'email', 'phone_number',
      'message', 'address'];
    const filteredData = excelData.map(obj => {
      const newObj = {};
      columnsToKeep.forEach(column => {
        if (obj.hasOwnProperty(column)) {
          let newColumnName = column.replace(/_/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
          newObj[newColumnName] = obj[column];
        }
      });
      return newObj;
    });
    exportToExcel(filteredData, "Contact Form");
  }

  const columns = [
    {
      name: "#",
      cell: (row, index) => (pagination.page - 1) * pagination.limit + (index + 1), //RDT provides index by default
      width: "50px"
    },
    {
      name: t("contactForm_manage_col_name"),
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: t("contactForm_manage_col_email"),
      selector: (row) => row.email,
      sortable: true
    },
    {
      name: t("contactForm_manage_col_phone_number"),
      selector: (row) => row.phone_number,
      sortable: true
    },
    {
      name: t("contactForm_manage_col_created_at"),
      selector: (row) => moment(row.created_at).format("YYYY-MM-DD"),
      sortable: true
    },
    {
      name: t("contactForm_manage_col_action"),
      selector: (m) => {
        return (
          <div className="d-flex gap-2">
              <Button onClick={() => handleOpen(m.id)}>
                <FaEye />
              </Button>
          </div>
        );
      }
    }
  ];

  return (
    <>
      <Layout>
        { isExcelLoading || isLoading && <CircleLoader/>}
        <header>
          <div className="d-flex justify-content-between">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item active>
                  {t("contactForm_manage_contactForms")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </header>
        <section id="albumList" className="mb-5 pb-5">
          <h2>{t("contactForm_manage_contactFormsList")}</h2>
          <div className="mt-3 mb-3">
            <Form
              onSubmit={handleSubmit((data) => {
                if (data.fromTo_date && data.fromTo_date.length === 2) {
                  data.fromDate = moment(data.fromTo_date[0]).format("yyyy-MM-DD");
                  data.toDate = moment(data.fromTo_date[1]).format("yyyy-MM-DD");
                }
                delete data.fromTo_date;
                filterDispatch({
                  type: "reInitialize",
                  payload: data
                });
              })}
            >
              <div className="row">
                <Form.Group controlId="fromTo_date" className="col-md-4 col-lg-5">
                  <Form.Label className="fw-bold">{t("clientForm_manage_search_formToDate")}</Form.Label>
                  <Controller
                    control={control}
                    name="fromTo_date"
                    render={({field}) => (
                      <DateRangePicker block {...field} format="yyyy-MM-dd" />
                    )}
                  />
                </Form.Group>
                <Form.Group
                  className="col-md-5 col-lg-5"
                  controlId="search"
                  onSubmit={handleSubmit((data) => {
                  })}
                >
                  <Form.Label className="fw-bold">{t("clientForm_manage_searchBy")}</Form.Label>
                  <Form.Control
                    {...register("term")}
                    placeholder={t("clientForm_manage_searchBy_p")}
                    custom
                  ></Form.Control>
                </Form.Group>
                <div className="col-md-3 col-lg-2">
                  <div className="h-100 d-flex justify-content-end align-items-center">
                    <Button className="px-5" type="submit">
                      {t("posts_manage_go")}
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
          <DataTable
            columns={columns}
            data={list?.data}
            progressPending={isLoading}
            pagination
            paginationServer
            paginationComponentOptions={{noRowsPerPage: true}}
            paginationTotalRows={list?.total}
            onChangePage={(page) => {
              changePagination({page});
            }}
            fixedHeader
            fixedHeaderScrollHeight="800px"
            highlightOnHover
          />
          <Button onClick={handleExcelButtonClick}>Export to Excel</Button>
        </section>
        <Modal size="lg" open={open} onClose={handleClose}>
          <Modal.Header>
            <Modal.Title>{t("details")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container" id="myPDF" style={{ letterSpacing: '0.01px', fontFamily: 'Arial' }}>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <div className="card mb-3">
                    <div className="card-header" style={{ backgroundColor:'#EEEEEE'}}>
                      <h5 className="mb-0">{t("contactForm_manage_col_information")}</h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="fw-bold">{t("contactForm_manage_col_name")}</label>
                            <p className="form-control-static">{selectedData?.name}</p>
                          </div>
                          <div className="form-group">
                            <label className="fw-bold">{t("contactForm_manage_col_email")}</label>
                            <p className="form-control-static">{selectedData?.email}</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="fw-bold">{t("contactForm_manage_col_phone_number")}</label>
                            <p className="form-control-static">{selectedData?.phone_number}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3" >
                  <div className="card mb-3">
                    <div className="card-header" style={{ backgroundColor:'#EEEEEE'}}>
                      <h5 className="mb-0">{t("contactForm_manage_col_business_information")}</h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="fw-bold">{t("contactForm_manage_col_message")}</label>
                            <p className="form-control-static">{selectedData?.address}</p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="fw-bold">{t("contactForm_manage_col_address")}</label>
                            <p className="form-control-static">{selectedData?.message}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <GenericPdfDownloader
              downloadFileName={ "Contact-Form-" + selectedData?.name}
              content={"myPDF"}
            />
            <Button onClick={handleClose} appearance="primary">
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </>
  );
}

export default ContactFormListPage;