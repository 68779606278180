import React ,{useState,useEffect} from 'react'
import './role.css';
import { Link , useNavigate,useParams} from 'react-router-dom';
import { useForm } from "react-hook-form";
import {Breadcrumb, Button, Form, Toast} from "react-bootstrap";
import http from "../../http"
import Layout from '../../components/layouts/layout';
import loginCheck from '../../helpers/loginCheck';
import Cookies from 'universal-cookie';
import { SelectPicker } from 'rsuite';
import {useLang} from "../../hooks";
import {BackButton} from "../../components/BackButton/BackButton";

const DeleteRole = () => {
    const { register, handleSubmit } = useForm();
    const [data,setData] = useState('');
    const [roles, setRoles] = useState('');
    const [keyss,setKeys] = useState([])
    const [valuess,setvalues] = useState([])
    const [getSuccess,setSuccess] = useState(false);
    const [getError,setError] = useState('');
    const [getErrorObj,setErrorObj] = useState({})
    const [users, setUsers] = useState([]);
    const [getLoader,setLoader] = useState(false);
    const [getRole,setRole] = useState([])
    const cookies = new Cookies();
    const {id} = useParams()
    const {t} = useLang();

    const navigate = useNavigate();

    useEffect(()=> {
        if(!loginCheck()){
            console.log("this works")
            navigate("/login");
        }
    });


    useEffect(()=>{
        fetchUsersRole();
    },[]);

    const fetchUsersRole = () => {
        http.get('role/users/get/'+id,{
            headers:{
              "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
             } 
          }).then(res=>{
            setRole(res.data.data);
        })
    }

    console.log(getRole)

    const getSearch = (val) =>{
        if(val.length > 0){
            http.get(`/admin/user/search/${val}`,{
                headers:{
                  "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                 } 
              }).then((response)=>{
                console.log(response.data.data.data)
                setUsers(response.data.data.data);
            })
        }else if(roles.user_id){
            http.get(`/admin/user/get/${roles.user_id}`,{
                headers:{
                  "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
                 } 
              }).then((response)=>{
                setUsers([response.data.data]);
            })
        } else {
            setUsers([]);
        }
    }

    function removeEmpty(obj) {
        return Object.fromEntries(
          Object.entries(obj)
            .filter(([_, v]) => v != null && v != "")
            .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
        );
      }
    const onSubmit = (data, e) => {       
        data = {...data, ...roles};
        // data.user_id = cookies.get('userAuth').id
        data = removeEmpty(data)
        console.log(data)
        setLoader(true)
        http.post(`/role/remove-role?id=${id}`,data, {
            headers:{
             "Authorization" :"Bearer "+ cookies.get('userAuth').token, 
            }  
        }
        )
        .then((res)=>{
            console.log(res);
            // setSuccess(true);
            localStorage.setItem("success", true);
            // setLoader(false);
            setTimeout(()=>{
              navigate("/assign-user-role-list");
              },1000)
            })
        .catch((err)=> {
            setLoader(false);
            console.log(err)
            // console.log(Object.keys(err.response.data.data))
            let errs = err.response.data.data;
            let keys = Object.keys(errs)
            console.log(keys);
            let errors = {};
            keys.map((key)=> errors[key] = errs[key][0])
            console.info(errors);
            setError(err.response.data)
            
            setErrorObj(errors)
        })
    }
    const onError = (errors, e) => console.log(errors, e);

    const handleChange = (e) =>{
        console.log(e);
        let role = {...roles};
        role[e.target.name] = e.target.value
        setRoles(role);
    }
  return (
    <>
        <Layout>
            {getLoader &&
              <div className="preloader">
                  <div className="overlay__inner">
                      <div className="overlay__content"><span className="spin"></span></div>
                  </div>
              </div>
            }
            <header>
                <div className="d-flex justify-content-between">
                    <Breadcrumb>
                        <li className="breadcrumb-item">
                            <Link to={`/role`}> {t("acl_roleList_roleList")}</Link>
                        </li>
                        <Breadcrumb.Item active>
                            <Link to={`/assign-user-role-list`}>{t("acl_userRoleList_userRoleList")}</Link>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <BackButton path={-1} text={t("taxonomies_manage_taxonomies")}/>
                </div>
            </header>
            <div className="form_design">
                <h2 style={{color: "black", textDecoration: "underline"}}>Delete Role from User</h2>
                <Form onSubmit={handleSubmit(onSubmit)}>


                    <Form.Group>
                        <Form.Label className="form_label">Role Name</Form.Label>
                        <SelectPicker
                          name="user_id"
                          data={getRole?.roles?.map((item) => ({label: item.name, value: item.id}))}
                          style={{width: "100%"}}
                          onChange={(value) => setRoles({...getRole, "role_id": value})}
                        />

                        {getErrorObj.role_id ?
                          <span className="text-danger">
                    The role name field is required.
                    </span> : null
                        }
                    </Form.Group>

                    <div className="mt-4">
                        <Button className="action__btn" variant="primary" type="submit">
                            Save
                        </Button>
                        <Link to="/assign-user-role-list">
                            <Button className="action__btn" variant="info">
                                Back to Home
                            </Button>
                        </Link>
                    </div>
                </Form>
            </div>
        </Layout>
    </>
  )
}

export default DeleteRole