import {useRef, useState} from "react";
import {Breadcrumb, Button} from "react-bootstrap";
import DataTable from "react-data-table-component";
import {FaSearch, FaEye} from "react-icons/fa";
import {MdDeleteOutline} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import {Input, InputGroup, Modal, Toggle} from "rsuite";
import Layout from "../../components/layouts/layout";
import {Permission} from "../../configs/constants";
import ComponentGuard from "../../helpers/ComponentGuard";
import {useNotification} from "../../hooks/useNotification";
import {useLang} from "../../hooks";
import {usePaginatedList} from "../../hooks/usePaginatedList";
import {
  getPaginatedBlogComment,
  removeBlogComment,
  updateBlogComment
} from "./blogComment.service";

export function BlogCommentListPage() {
  const searchBoxRef = useRef();
  const {t} = useLang();
  const {
    list,
    changePagination,
    fetch,
    error,
    isLoading,
    pagination,
    filterDispatch,
    removeItem
  } = usePaginatedList(getPaginatedBlogComment, removeBlogComment);

  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const {notifyError, notifySuccess} = useNotification();
  const navigate = useNavigate();

  if (error) {
    notifyError(error);
    setTimeout(() => navigate("/"), 200);
  }

  const handleOpen = (id) => {
    const data = list?.data.find((item) => item.id === id);
    setSelectedData(data);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleToggleChange = (isShow) => {
    setSelectedData((prevData) => ({
      ...prevData,
      is_show: isShow ? 1 : 0
    }));
  };

  const handleReplyToggleChange = (replyId, isShow) => {
    const updatedList = list.data.map((comment) =>
      comment.id === replyId ? {...comment, is_show: isShow ? 1 : 0} : comment
    );
    list.data = updatedList;
    setSelectedData((prevData) => ({
      ...prevData,
      replies: updatedList.filter((reply) => reply.parentid === prevData.id)
    }));
  };

  const handleSave = async () => {
    try {
      await updateBlogComment(selectedData.id, selectedData);
      notifySuccess({title: "Comment updated successfully"});
      fetch();
      handleClose();
    } catch (err) {
      console.error("Failed to update comment status:", err);
    }
  };

  const handleSaveReply = async (replyId) => {
    try {
      const reply = list.data.find((comment) => comment.id === replyId);
      console.log(reply);
      await updateBlogComment(replyId, reply);
      notifySuccess({title: "Reply updated successfully"});
    } catch (err) {
      console.error("Failed to update reply status:", err);
      notifyError({title: "Failed to update", message: err.message});
    }
  };

  const handleDelete = async (id) => {
    try {
      await removeItem(id);
      notifySuccess({
        title: "Item removed successfully"
      });
    } catch (err) {
      notifyError({
        title: "Failed to remove",
        message: err.message
      });
    }
  };

  const columns = [
    {
      name: "#",
      cell: (row, index) =>
        (pagination.page - 1) * pagination.limit + (index + 1), // RDT provides index by default
      width: "50px"
    },
    {
      name: t("blog_comment_name"),
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: t("blog_comment_email"),
      selector: (row) => row.email
    },
    {
      name: t("blog_comment_phone"),
      selector: (row) => row.phone
    },
    {
      name: t("pages_manage_col_action"),
      selector: (m) => {
        return (
          <div className="d-flex gap-2">
            <ComponentGuard needsPermission={[Permission.BlogCommentGet]}>
              <Button onClick={() => handleOpen(m.id)}>
                <FaEye />
              </Button>
            </ComponentGuard>
            <ComponentGuard needsPermission={[Permission.BlogCommentDelete]}>
              <Button onClick={() => handleDelete(m.id)} variant="danger">
                <MdDeleteOutline />
              </Button>
            </ComponentGuard>
          </div>
        );
      }
    }
  ];

  const filteredData = list.data.filter(
    (comment) => comment.parent_id === null
  );

  return (
    <>
      <Layout>
        <header>
          <div className="d-flex justify-content-between">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item active>
                  {t("blog_comment")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </header>
        <section id="list" className="mb-5 pb-5">
          <h2>{t("blog_comment_list")}</h2>
          <DataTable
            columns={columns}
            data={filteredData}
            progressPending={isLoading}
            pagination
            paginationServer
            paginationComponentOptions={{noRowsPerPage: true}}
            paginationTotalRows={list.total}
            onChangePage={(page) => {
              changePagination({page});
            }}
            fixedHeader
            fixedHeaderScrollHeight="800px"
            highlightOnHover
            subHeader
            subHeaderComponent={
              <InputGroup className="mb-3" style={{width: "300px"}}>
                <Input
                  ref={searchBoxRef}
                  placeholder={t("pages_manage_searchByName")}
                />
                <InputGroup.Button
                  onClick={() => {
                    filterDispatch({
                      action: "set",
                      payload: {key: "term", value: searchBoxRef.current.value}
                    });
                  }}
                >
                  <FaSearch />
                </InputGroup.Button>
              </InputGroup>
            }
            subHeaderAlign="right"
          />
        </section>
        <Modal size="lg" open={open} onClose={handleClose}>
          <Modal.Header>
            <Modal.Title>{t("blogComment_details")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container mt-5">
              <div className="row">
                <div className="col-md-8">
                  <h5 className="fw-bold">{t("blog_comment_comments")}</h5>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="card flex-grow-1 me-3">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-auto">
                            <h5 className="card-title fw-bold">
                              {selectedData?.name}
                            </h5>
                          </div>
                          <div className="col-auto">
                            <h6 className="card-title">
                              ({selectedData?.email})
                            </h6>
                          </div>
                        </div>
                        <p className="card-text">{selectedData?.comment}</p>
                      </div>
                    </div>
                    <ComponentGuard needsPermission={[Permission.BlogCommentStatusUpdate]}>
                      <Toggle
                        size="lg"
                        checked={selectedData?.is_show === 1}
                        onChange={(value) => handleToggleChange(value)}
                        checkedChildren={<span>Show</span>}
                        unCheckedChildren={<span>Hide</span>}
                        className="ms-3"
                      />
                      <Button
                        className="ms-3 mb-2"
                        variant="success"
                        onClick={handleSave}
                      >
                        {t("save_btn")}
                      </Button>
                    </ComponentGuard>
                  </div>
                  <h5 className="d-flex justify-content-between align-items-center mb-3 ms-5 fw-bold">
                    {t("blog_comment_replies")}
                  </h5>
                  {list?.data
                    .filter((reply) => reply.parent_id === selectedData?.id)
                    .map((reply) => (
                      <div
                        key={reply.id}
                        className="d-flex justify-content-between align-items-center mb-3 ms-5"
                      >
                        <div className="card flex-grow-1 me-3">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-auto">
                                <h5 className="card-title fw-bold">
                                  {reply.name}
                                </h5>
                              </div>
                              <div className="col-auto">
                                <h6 className="card-title">({reply.email})</h6>
                              </div>
                            </div>
                            <p className="card-text">{reply.comment}</p>
                          </div>
                        </div>
                        <Toggle
                          size="lg"
                          checked={reply.is_show === 1}
                          onChange={(value) =>
                            handleReplyToggleChange(reply.id, value)
                          }
                          checkedChildren={<span>Show</span>}
                          unCheckedChildren={<span>Hide</span>}
                          className="ms-3"
                        />
                        <Button
                          className="ms-3 mb-2"
                          variant="success"
                          onClick={() => handleSaveReply(reply.id)}
                        >
                          {t("save_btn")}
                        </Button>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose} appearance="primary">
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </Layout>
    </>
  );
}
