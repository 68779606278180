import React from "react";
import {useRef, useState} from "react";
import DataTable from "react-data-table-component";
import {usePaginatedList} from "../../hooks/usePaginatedList";
import {
  getPaginatedSubscription,
  removeSubscriber,
  emailSubscriber
} from "./subscription.service";
import {useNotification} from "../../hooks/useNotification";
import {useNavigate} from "react-router-dom";
import ComponentGuard from "../../helpers/ComponentGuard";
import {Permission} from "../../configs/constants";
import Layout from "../../components/layouts/layout";
import {Breadcrumb, Button} from "react-bootstrap";
import {Input, InputGroup, Modal} from "rsuite";
import {FaSearch} from "react-icons/fa";
import {MdDeleteOutline} from "react-icons/md";
import {FloatingButton} from "../../components";
import {CgPlayListAdd} from "react-icons/cg";
import {Badge} from "react-bootstrap";
import {useLang} from "../../hooks";
import {InputValidationError} from "../../libs/error";

function SubscriptionListPage() {
  const [selectedItems, setSelectedItems] = useState("");
  const searchBoxRef = useRef();
  const {t} = useLang();
  const {
    list,
    changePagination,
    error,
    isLoading,
    pagination,
    filterDispatch,
    removeItem
  } = usePaginatedList(
    getPaginatedSubscription,
    removeSubscriber,
    emailSubscriber
  );

  const {notifyError, notifySuccess} = useNotification();

  const [modelOpen, setModelOpen] = useState(false);
  const handleClose = () => {
    setModelOpen(false);
    setEmailValue("");
  };
  const handleOpen = () => {
    setModelOpen(true);
  };

  const [emailValue, setEmailValue] = useState("");

  const navigate = useNavigate();

  const handleChange = ({selectedRows}) => {
    let subscriberEmails = selectedRows.map((item) => item.email);
    setSelectedItems((subscriberEmails = subscriberEmails.join(",")));
  };

  //send email to subscriber
  const handleSend = async () => {
    try {
      const data = {
        emailList: selectedItems,
        message: emailValue
      };
      const post = await emailSubscriber(data);
      notifySuccess({
        title: "Email send successfully",
        message: `Email send successfully!`
      });
    } catch (err) {
      if (err instanceof InputValidationError) {
        notifyError({
          title: "Not Send email",
          message: err.message
        });
        return;
      }

      notifyError({
        title: "Failed to send email",
        message: err.message
      });
    } finally {
      setModelOpen(false);
      setEmailValue("");
    }
  };

  if (error) {
    notifyError(error);
    setTimeout(() => navigate("/"), 200);
  }

  const handleDelete = async (id) => {
    try {
      await removeItem(id);
      notifySuccess({
        title: "Item removed successfully"
      });
    } catch (err) {
      notifyError({
        title: "Failed remove",
        message: err.message
      });
    }
  };
  const columns = [
    {
      name: "#",
      cell: (row, index) =>
        (pagination.page - 1) * pagination.limit + (index + 1),
      width: "50px"
    },
    {
      name: t("subscribe_list_column_name"),
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: t("subscribe_list_column_email"),
      selector: (row) => row.email
    },
    {
      name: t("subscribe_list_column_phone_number"),
      selector: (row) => row.phone_no
    },
    {
      name: t("status"),
      selector: (row) =>
        row.status === "active" ? (
          <Badge bg="success">Active</Badge>
        ) : (
          <Badge bg="warning">InActive</Badge>
        ),
      sortable: true
    },
    {
      name: t("action"),
      selector: (m) => {
        return (
          <div className="d-flex gap-2">
            <ComponentGuard needsPermission={[Permission.SubscriberDelete]}>
              <Button onClick={() => handleDelete(m.id)} variant="danger">
                <MdDeleteOutline />
              </Button>
            </ComponentGuard>
          </div>
        );
      }
    }
  ];
  return (
    <>
      <Layout>
        <header>
          <div className="d-flex justify-content-between">
            <div>
              <Breadcrumb>
                <Breadcrumb.Item active>{t("subscribe")}</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </header>
        <section id="list" className="mb-5 pb-5">
          <h2>{t("subscribe_list")}</h2>
          <DataTable
            columns={columns}
            data={list.data}
            selectableRows={true}
            onSelectedRowsChange={handleChange}
            progressPending={isLoading}
            pagination
            paginationServer
            paginationComponentOptions={{noRowsPerPage: true}}
            paginationTotalRows={list.total}
            onChangePage={(page) => {
              changePagination({page});
            }}
            fixedHeader
            fixedHeaderScrollHeight="800px"
            highlightOnHover
            subHeader
            subHeaderComponent={
              <InputGroup className="mb-3" style={{width: "300px"}}>
                
                <Input
                  ref={searchBoxRef}
                  placeholder={t("pages_manage_searchByName")}
                />
                <InputGroup.Button
                  onClick={() => {
                    filterDispatch({
                      type: "set",
                      payload: {
                        key: "term",
                        value: searchBoxRef.current.value
                      }
                    });
                  }}
                >
                  <FaSearch />
                </InputGroup.Button>
              </InputGroup>
            }
            subHeaderAlign="right"
          />
        </section>
        <Button size="lg" className="mt-2" onClick={() => handleOpen()}>
                  {t("Send_Email")}
        </Button>
      </Layout>
      <ComponentGuard needsPermission={[Permission.SubscriberStore]}>
        <FloatingButton
          onClick={() => {
            navigate("/subscription/create");
          }}
          icon={<CgPlayListAdd />}
          name={t("subscribe_add")}
        />
      </ComponentGuard>
      <Modal size="sm" open={modelOpen} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>{t("subscribe_list_column_email")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Input
            as="textarea"
            rows={3}
            placeholder="Textarea"
            value={emailValue}
            onChange={setEmailValue}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle" className="mr-2">
            Cancel
          </Button>
          <Button onClick={handleSend} appearance="primary">
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SubscriptionListPage;
